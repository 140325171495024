import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';

import { DESCRIPTION_TEXT } from '../constants';
import { useSession } from '../context/SessionContext';

const WelcomePage = () => {
  const { isUserAuthenticated, signIn } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, isUserAuthenticated]);

  return (
    <>
      <Box display='flex' justifyContent='center' width='100%' sx={{ mt: 20 }}>
        <Box display='flex' flexDirection='column' width='50%'>
          <Box display='flex' alignItems='center' sx={{ mb: 3 }}>
            <Box
              component='img'
              src='/chaas-main.png'
              alt='Chaas'
              sx={{
                width: 50,
                height: 50,
                objectFit: 'contain',
              }}
            />
            <Typography variant='h4' component='h1' sx={{ fontWeight: 700 }}>
              Chaas
            </Typography>
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              component='p'
              sx={{ fontWeight: 500, ml: 1 }}
            >
              Chatbot as a Service
            </Typography>

            <Typography
              variant='body1'
              component='p'
              sx={{ mt: 2, ml: 1, color: 'text.secondary' }}
            >
              {DESCRIPTION_TEXT}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='30%'
        sx={{
          py: 4,
          textAlign: 'center',
          gap: 2,
          backgroundColor: 'background.default',
          borderRadius: 2,
          boxShadow: 3,
          mx: 'auto',
          p: 3,
          marginTop: 10,
        }}
      >
        <Typography variant='h5' sx={{ fontWeight: 600 }}>
          Get Started!
        </Typography>

        <Button
          variant='contained'
          color='primary'
          sx={{
            width: '200px',
            padding: '12px',
            fontWeight: 600,
            textTransform: 'none',
            borderRadius: 2,
            boxShadow: 2,
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={signIn}
        >
          Sign In
        </Button>
      </Box>
      {/* <Box display='flex' justifyContent='center' width='100%' sx={{ mt: 10 }}>
        <Box width='50%' textAlign='left'>
          <Typography
            variant='body1'
            component='p'
            sx={{ color: 'text.secondary' }}
          >
            {MARKETING_TEXT}
          </Typography>
        </Box>
      </Box> */}
    </>
  );
};

export default WelcomePage;
