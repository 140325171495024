import HomeSitesSection from '../../components/HomeSitesSection';
import HomeTopicsSection from '../../components/HomeTopicsSection';

const DashboardHome = () => {
  return (
    <>
      <HomeSitesSection />
      <HomeTopicsSection />
    </>
  );
};

export default DashboardHome;
