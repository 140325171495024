export const styles = {
  urlForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginTop: 3,
  },
  extensionCard: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    width: 320,
    borderRadius: 2,
    boxShadow: 4,
    zIndex: 999,
  },
  siteCard: {
    borderRadius: 2,
    boxShadow: 3,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chipBox: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  siteCardActions: {
    padding: 2,
    marginTop: 'auto',
  },
  addSiteFAB: {
    color: 'black',
    zIndex: 0,
  },
  createTopicFAB: {
    color: 'black',
    zIndex: 0,
  },
  dashboardTitle: {
    flexGrow: 1,
    mr: 2,
    mt: 6,
    ml: 2,
  },
  dashboardSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 2,
  },
  emptySitesCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    my: 20,
  },
  deleteSiteButton: {
    margin: 2,
    marginLeft: 0,
  },
  copyButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  dialogActions: {
    pr: 2,
    pb: 3,
  },
  configureDialogBox: {
    marginTop: 0.5,
    display: 'flex',
    gap: 1,
  },
  divider: {
    marginRight: 3,
    marginLeft: 3,
  },
  wrapper: {
    height: '100vh',
  },
  chatButton: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '9px',
    cursor: 'pointer',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textTransform: 'none',
    width: '168px',
    height: '100%',
  },
  chatContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#808080',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  messagesArea: {
    flex: 1,
    padding: 2,
    height: '100%',
    overflowY: 'auto',
  },
  inputArea: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    borderRadius: 0,
    borderTop: '1px solid #ccc',
  },
  input: {
    flex: 1,
    padding: 2,
    borderRadius: 1.5,
    border: '1px solid #ccc',
    margin: 1,
    lineHeight: '1.15em',
  },
  display: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  displayChatStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loadingText: {
    marginLeft: 2,
  },
  loaderIcon: {
    marginTop: '3px',
  },
  header: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 2,
    backgroundColor: 'black',
  },
  image: {
    height: 'auto',
    width: 35,
    marginLeft: 3,
    padding: 4,
  },
  messageCard: {
    padding: '10px',
    borderRadius: '4px',
  },
  chatbotTitle: {
    flexGrow: 1,
  },
  ellipsisContainer: {
    padding: '10px',
    borderRadius: '4px',
  },
  chatLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '50px',
  },
  chatTabContainer: {
    height: '100%',
    width: '100%',
  },
  chatTabHeader: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  chatTabContent: {
    marginTop: 1,
    height: '100%',
  },
  feedback: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  progressBar: {
    borderRadius: '10px',
    height: '7px',
  },
  publicChatGrid: {
    height: '100vh',
    marginTop: '30px',
    marginBottom: '30px',
  },
  dashboardListItemButton: {
    marginLeft: '16px',
    alignSelf: 'flex-start',
  },
  dashboardTopicBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px',
  },
  themeSwitcher: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: 'text.primary',
    borderRadius: 1,
    p: 3,
    minHeight: '56px',
  },
  widgetHeaderIconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  dashboardHeaderIconsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1,
    mr: 0.75,
  },
  wrappedText: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  syntaxHighlight: {
    border: '1px solid #c3c3c3',
    borderRadius: '5px',
  },
  pageContentTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rawMDBackground: {
    background: 'black',
    color: 'white',
  },
  traceContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  messageTraceBackground: {
    background: 'black',
    color: 'white',
    padding: 2,
    borderRadius: 2,
  },
  messageTraceTokensContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    justifyContent: 'space-between',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
