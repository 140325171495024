import { v4 as uuid } from 'uuid';

export const getOrCreateChatUUID = (source: string, siteId: string): string => {
  const storageKey = 'chatUUIDMap';
  const storedData = localStorage.getItem(storageKey);
  const chatUUIDMap: Record<string, Record<string, string>> = storedData
    ? JSON.parse(storedData)
    : {};

  // Ensure the `source` exists in the map
  if (!chatUUIDMap[source]) {
    chatUUIDMap[source] = {};
  }

  // Ensure the `siteId` exists within the `source`
  if (!chatUUIDMap[source][siteId]) {
    chatUUIDMap[source][siteId] = uuid();
    localStorage.setItem(storageKey, JSON.stringify(chatUUIDMap));
  }

  return chatUUIDMap[source][siteId];
};

export const removeChatUUID = (source: string, siteId: string): void => {
  const storageKey = 'chatUUIDMap';
  const storedData = localStorage.getItem(storageKey);

  if (!storedData) {
    return;
  }

  const chatUUIDMap: Record<string, Record<string, string>> = JSON.parse(
    storedData,
  );

  if (chatUUIDMap[source] && chatUUIDMap[source][siteId]) {
    // Remove the specific siteId from the source
    delete chatUUIDMap[source][siteId];

    // If the source map becomes empty, delete it
    if (Object.keys(chatUUIDMap[source]).length === 0) {
      delete chatUUIDMap[source];
    }

    localStorage.setItem(storageKey, JSON.stringify(chatUUIDMap));
  }
};
