import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { styles } from '../styles/stylesheet';

type Props = {
  open: boolean;
  siteField: {
    fieldName: string;
    display: string;
  };
  onClose: () => void;
  onAction: (fieldName: string, value: string) => Promise<void>;
};

type FormValues = {
  fieldValue: string;
};

export default function UpdateSiteDialog({
  open,
  siteField,
  onClose: handleClose,
  onAction: handleAction,
}: Props) {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    defaultValues: { fieldValue: '' },
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!data.fieldValue.trim()) {
        setError('fieldValue', {
          type: 'required',
          message: 'Field is required.',
        });

        return;
      }

      try {
        await handleAction(siteField.fieldName, data.fieldValue);
      } finally {
        handleClose();
        reset();
      }
    },
    [handleAction, handleClose, reset, siteField.fieldName, setError],
  );

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>{`Enter the new ${siteField.display}`}</DialogTitle>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ marginTop: 1 }}>
            <Controller
              name='fieldValue'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={siteField.display}
                  variant='outlined'
                  fullWidth
                  autoComplete='off'
                  error={!!errors.fieldValue}
                  helperText={errors.fieldValue?.message}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <LoadingButton
            type='submit'
            color='primary'
            variant='contained'
            size='small'
            loading={isSubmitting}
          >
            Update
          </LoadingButton>
          <Button
            onClick={() => {
              handleClose();
              reset();
            }}
            size='small'
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
