import SyntaxHighlighter from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Ref: Styles provided - https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_STYLES_HLJS.MD
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNotifications } from '@toolpad/core';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { styles } from '../styles/stylesheet';

type Props = {
  code: string;
  language: string;
  open: boolean;
  handleClose: () => void;
};

export default function CodeBlock({
  code,
  language,
  open,
  handleClose,
}: Props) {
  const notifications = useNotifications();

  const handleCopy = () => {
    notifications.show('Code copied to clipboard', {
      severity: 'info',
      autoHideDuration: 3000,
    });
  };

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogTitle>Embed Code</DialogTitle>
      <DialogContent>
        <div style={{ position: 'relative', width: '100%' }}>
          <CopyToClipboard text={code} onCopy={handleCopy}>
            <Button sx={styles.copyButton}>
              <ContentCopyIcon />
            </Button>
          </CopyToClipboard>
          <SyntaxHighlighter
            language={language}
            style={vs2015}
            wrapLines={false}
            wrapLongLines={false}
            customStyle={styles.syntaxHighlight}
          >
            {code}
          </SyntaxHighlighter>
        </div>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button
          onClick={handleClose}
          color='primary'
          variant='contained'
          size='small'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
