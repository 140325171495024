import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import {
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
  ThemeProvider,
} from '@mui/material';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import { useNotifications } from '@toolpad/core';

import { PageContentData, PageData, PageStatus, SiteData } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import PageContentDialog from './PageContentDialog';
import { pageContentTheme } from '../theme/theme';

const PagesTable = ({
  site,
  pagesCount,
}: {
  site: SiteData;
  pagesCount: number;
}) => {
  const [pages, setPages] = useState<PageData[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [isPageContentOpen, setIsPageContentOpen] = useState(false);
  const [selectedPageContent, setSelectedPageContent] =
    useState<PageContentData>();
  const [selectedPageUrl, setSelectedPageUrl] = useState<string>();

  const { apiClient } = useApiClient();
  const notifications = useNotifications();

  const handleRowsPerPageChange = async (event: any) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const handlePageChange = async (event: any, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const fetchPagesData = useCallback(async () => {
    try {
      const pagesResponse = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/pages?pageNumber=${currentPage}&pageSize=${pageSize}`,
      );
      setPages(pagesResponse.data.pages);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [site.id, currentPage, pageSize, apiClient]);

  useEffect(() => {
    fetchPagesData();
  }, [fetchPagesData]);

  const fetchPageById = useCallback(
    async (pageId: string) => {
      const pageContentResponse = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}/content`,
      );

      const parsedContent = JSON.parse(pageContentResponse.data.content);

      setSelectedPageContent({
        text: parsedContent.text,
        metadata: JSON.stringify(parsedContent.metadata, null, 2),
      });
    },
    [apiClient],
  );

  const handleOpenPageContent = useCallback(
    async (pageId: string, url: string) => {
      try {
        await fetchPageById(pageId);
        setSelectedPageUrl(url);
        setIsPageContentOpen(true);
      } catch (error) {
        console.error('Error fetching page content:', error);
        notifications.show('Error fetching page content!', {
          severity: 'error',
          autoHideDuration: 3000,
        });
      }
    },
    [fetchPageById, notifications],
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Page URL</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align='right'>Depth</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages && pages.length > 0 ? (
              pages.map((page: PageData) => (
                <>
                  <TableRow key={page.id}>
                    <TableCell>
                      <IconButton
                        size='small'
                        onClick={() => {
                          handleOpenPageContent(page.id, page.url);
                        }}
                      >
                        <FileOpenOutlinedIcon />
                      </IconButton>
                      <Link
                        underline='hover'
                        target='_blank'
                        rel='noopener'
                        href={page.url}
                        sx={{ paddingLeft: 3 }}
                      >
                        {page.url}
                      </Link>
                    </TableCell>
                    <TableCell
                      sx={[
                        (theme) => ({
                          color:
                            page.status === PageStatus.Failed
                              ? theme.palette.error.main
                              : 'inherit',
                        }),
                      ]}
                    >
                      {page.status.charAt(0).toUpperCase() +
                        page.status.slice(1)}
                    </TableCell>
                    <TableCell align='right'>{page.depth}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(page.createdAt).toRelative()}
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No pages available</TableCell>
              </TableRow>
            )}
          </TableBody>
          {pagesCount > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  colSpan={4}
                  count={pagesCount}
                  rowsPerPage={pageSize}
                  page={currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {selectedPageContent && selectedPageUrl && (
        <ThemeProvider theme={pageContentTheme}>
          <PageContentDialog
            content={selectedPageContent}
            onClose={() => setIsPageContentOpen(false)}
            open={isPageContentOpen}
            url={selectedPageUrl}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default PagesTable;
