import { Link as RouterLink } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Card,
  Typography,
  Chip,
  Button,
  Link,
  CardHeader,
  CircularProgress,
  CardActions,
  LinearProgress,
  CardContent,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles';

import { styles } from '../styles/stylesheet';
import { SiteData, Status } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import { SITE_POLL_INTERVAL } from '../constants';
import { useIndexingProgress } from '../hooks/UseIndexingProgress';

const SiteCard = ({
  site,
  handleEmbedCodeOpen,
}: {
  site: SiteData;
  handleEmbedCodeOpen: (siteId: string) => void;
}) => {
  const [status, setStatus] = useState<Status>(site.status);
  const [pagesCount, setPagesCount] = useState(site.pagesCount);

  const { apiClient } = useApiClient();

  const {
    indexedPagesCount,
    totalPagesCount: totalPagesCountAfterScraping,
    normalizeIndexedPagesCount,
  } = useIndexingProgress(site.id, apiClient, status);

  const theme = useTheme();

  const fetchSiteStatus = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/status`,
      );
      const statusResponse = response.data.status;
      setStatus(statusResponse);
    } catch (error) {
      console.error('Error fetching site status:', error);
    }
  }, [site.id, apiClient]);

  const fetchSitePagesCount = useCallback(async () => {
    try {
      const countResponse = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/pages/count`,
      );
      setPagesCount(countResponse.data.pagesCount);
    } catch (error) {
      console.error('Error fetching pages count:', error);
    }
  }, [site.id, apiClient]);

  useEffect(() => {
    if (status === Status.Ready) {
      return;
    }

    fetchSiteStatus();

    const timerId = setInterval(() => {
      fetchSiteStatus();
    }, SITE_POLL_INTERVAL);

    return () => clearInterval(timerId);
  }, [fetchSiteStatus, status]);

  useEffect(() => {
    if (status !== Status.Scraping) {
      return;
    }

    fetchSitePagesCount();

    const timerId = setInterval(() => {
      fetchSitePagesCount();
    }, SITE_POLL_INTERVAL);

    return () => clearInterval(timerId);
  }, [fetchSitePagesCount, status]);

  useEffect(() => {
    if (totalPagesCountAfterScraping) {
      setPagesCount(totalPagesCountAfterScraping);
    }
  }, [totalPagesCountAfterScraping]);

  return (
    <Card sx={styles.siteCard}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex' }}>
            <Link
              href={site.url}
              underline='always'
              target='_blank'
              rel='noopener'
              title={site.url}
            >
              <Typography
                variant='subtitle1'
                color='textPrimary'
                maxWidth='27vw'
                sx={styles.ellipsis}
              >
                {site.url}
              </Typography>
            </Link>
            {!site.isPublic && (
              <LockOutlined
                sx={{ marginLeft: 'auto', alignSelf: 'flex-start' }}
              />
            )}
          </Box>
        }
      />
      <CardContent sx={{ paddingY: 1 }}>
        {status === Status.Indexing ? (
          <LinearProgress
            variant='determinate'
            color='inherit'
            value={normalizeIndexedPagesCount(indexedPagesCount)}
            sx={styles.progressBar}
          />
        ) : (
          <Box sx={styles.progressBar} />
        )}
      </CardContent>
      <CardActions disableSpacing sx={styles.siteCardActions}>
        <Button
          sx={{ marginRight: 1 }}
          component={RouterLink}
          to={`/dashboard/sites/${site.id}`}
          size='small'
          variant='contained'
        >
          INFO
        </Button>
        <Button
          sx={{ marginRight: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            handleEmbedCodeOpen(site.id);
          }}
          size='small'
          variant='contained'
        >
          EMBED
        </Button>
        <Box sx={styles.chipBox}>
          <Chip
            label={
              <>
                {status !== Status.Ready && (
                  <CircularProgress
                    size={11}
                    color='inherit'
                    sx={{ marginRight: 1, marginTop: 0.5 }}
                  />
                )}
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </>
            }
            variant='outlined'
            color={status === Status.Ready ? 'primary' : 'default'}
            sx={{
              marginRight: 1,
              color:
                status === Status.Ready ? theme.palette.primary.main : 'grey',
            }}
          />
          <Chip
            label={pagesCount}
            color='primary'
            variant='outlined'
            icon={<DescriptionIcon />}
          />
        </Box>
      </CardActions>
    </Card>
  );
};

export default SiteCard;
