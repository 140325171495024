import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Card } from '@mui/material';

import { ExtensionTheme } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import { styles } from '../styles/stylesheet';

function ExtensionThemeSwitcher({
  id,
  theme,
}: {
  id: string;
  theme: ExtensionTheme;
}) {
  const { apiClient } = useApiClient();
  const [extensionTheme, setExtensionTheme] = useState<ExtensionTheme>(theme);

  // TODO: Implement for topic later
  const updateSiteTheme = useCallback(
    async (selectedTheme: ExtensionTheme) => {
      await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${id}`,
        {
          extensionTheme: selectedTheme,
        },
      );
    },
    [apiClient, id],
  );

  return (
    <Card>
      <Box sx={styles.themeSwitcher}>
        <FormControl>
          <FormLabel id='theme-toggle'>Theme</FormLabel>
          <RadioGroup
            aria-labelledby='theme-toggle'
            name='theme-toggle'
            row
            value={extensionTheme}
            onChange={(event) => {
              setExtensionTheme(
                event.target.value as 'system' | 'light' | 'dark',
              );
              updateSiteTheme(event.target.value as ExtensionTheme);
            }}
          >
            <FormControlLabel
              value='system'
              control={<Radio />}
              label='System'
            />
            <FormControlLabel value='light' control={<Radio />} label='Light' />
            <FormControlLabel value='dark' control={<Radio />} label='Dark' />
          </RadioGroup>
        </FormControl>
      </Box>
    </Card>
  );
}

export default ExtensionThemeSwitcher;
