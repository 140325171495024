import React, { useState, useCallback } from 'react';

import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { styles } from '../styles/stylesheet';

type Props = {
  dialogMessage: string;
  actionMessage?: string;
  open: boolean;
  onClose: () => void;
  onAction: () => void;
};

export default function ConfirmationDialog({
  dialogMessage,
  actionMessage = 'Confirm',
  open,
  onClose: handleClose,
  onAction: handleAction,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await handleAction();
    } finally {
      setIsLoading(false);
    }
  }, [handleAction]);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogContent>{dialogMessage}</DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <LoadingButton
          onClick={handleClick}
          color='primary'
          variant='contained'
          size='small'
          loading={isLoading}
        >
          {actionMessage}
        </LoadingButton>
        <Button onClick={handleClose} size='small' disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
