import { useCallback, useRef } from 'react';

export const useThrottle = <T extends (...args: any[]) => void>(
  callback: T,
  limit: number,
): ((...args: Parameters<T>) => void) => {
  const lastCallRef = useRef<number>(0);

  const throttledFunction = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();
      if (now - lastCallRef.current >= limit) {
        lastCallRef.current = now;
        callback(...args);
      }
    },
    [callback, limit],
  );

  return throttledFunction;
};
