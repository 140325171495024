import React, { useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotifications } from '@toolpad/core/useNotifications';

import { styles } from '../styles/stylesheet';
import { useApiClient } from '../context/ApiClientContext';
import { LLM_PROVIDERS } from '../constants';
import { isValidUrl } from '../utils/misc';

type Props = {
  open: boolean;
  handleClose: () => void;
  fetchSites: () => Promise<void>;
};

type FormValues = {
  url: string;
  apiKey: string;
  isPublic: boolean;
  llmProvider: string;
};

function AddSiteDialog({ open, handleClose, fetchSites }: Props) {
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors },
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      url: '',
      apiKey: '',
      isPublic: false,
      llmProvider: LLM_PROVIDERS[0].value,
    },
  });

  const llmProvider = watch('llmProvider');

  const { apiClient } = useApiClient();
  const notifications = useNotifications();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!isValidUrl(data.url)) {
        setError('url', {
          // When a custom validation check fails
          type: 'validate',
          message: 'Please enter a valid URL.',
        });

        return;
      }

      if (!data.apiKey.trim()) {
        setError('apiKey', {
          type: 'required',
          message: 'Field is required.',
        });

        return;
      }

      try {
        await apiClient.post(
          `${process.env.REACT_APP_BACKEND_URL}/sites/index`,
          {
            url: data.url,
            llmKey: data.apiKey,
            isPublic: data.isPublic,
            llmProvider: data.llmProvider,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        notifications.show('Scraping initiated!', {
          severity: 'success',
          autoHideDuration: 3000,
        });

        handleClose();
        fetchSites();
      } catch (error: any) {
        console.error('Error:', error);
        if (error.status === 422) {
          notifications.show(error.response.data, {
            severity: 'error',
            autoHideDuration: 3000,
          });
        } else {
          notifications.show('An error occurred while making the request.', {
            severity: 'error',
            autoHideDuration: 3000,
          });
        }
      } finally {
        reset();
      }
    },
    [handleClose, fetchSites, notifications, apiClient, reset, setError],
  );

  const apiKeyLink = useMemo(() => {
    return LLM_PROVIDERS.find((provider) => provider.value === llmProvider)
      ?.apiKeyLink;
  }, [llmProvider]);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>Enter the details to index the website</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.urlForm}
        >
          <Controller
            name='url'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='URL'
                variant='outlined'
                fullWidth
                autoComplete='off'
                error={!!errors.url}
                helperText={errors.url?.message}
              />
            )}
          />
          <Controller
            name='llmProvider'
            control={control}
            render={({ field }) => (
              <TextField {...field} select variant='outlined' label='Model'>
                {LLM_PROVIDERS.map((provider) => (
                  <MenuItem key={provider.value} value={provider.value}>
                    {provider.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Typography
            variant='body2'
            color='textSecondary'
            style={{ marginTop: '10px' }}
          >
            Visit{' '}
            <a href={apiKeyLink} target='_blank' rel='noopener noreferrer'>
              {apiKeyLink}
            </a>{' '}
            to get the API key.
          </Typography>
          <Controller
            name='apiKey'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='API Key'
                variant='outlined'
                fullWidth
                type='password'
                autoComplete='off'
                error={!!errors.apiKey}
                helperText={errors.apiKey?.message}
              />
            )}
          />
          <Controller
            name='isPublic'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={field.value} color='primary' />
                }
                label='Public'
              />
            )}
          />
          <DialogActions>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              color='primary'
              variant='contained'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddSiteDialog;
