import React, { useState } from 'react';

import { Box, IconButton, InputBase, Paper, useTheme } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { styles } from '../styles/stylesheet';

export default function ChatInputContainer({
  onMessageSend,
  isSendDisabled,
}: {
  onMessageSend: (question: string) => void;
  isSendDisabled: boolean;
}) {
  const [question, setQuestion] = useState('');
  const theme = useTheme();

  const handleMessageSend = () => {
    onMessageSend(question);
    setQuestion('');
  };

  return (
    <Box
      component={Paper}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.background.default,
          ...styles.inputArea,
        }),
      ]}
    >
      <InputBase
        fullWidth
        placeholder='Ask a question...'
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleMessageSend();
          }
        }}
        sx={styles.input}
        multiline
        minRows={2}
        maxRows={5}
      />
      <IconButton
        onClick={handleMessageSend}
        disabled={isSendDisabled}
        sx={{ color: theme.palette.grey[600] }}
      >
        <SendOutlinedIcon />
      </IconButton>
    </Box>
  );
}
