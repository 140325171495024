import React from 'react';

const ChatTabWrapper = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <div hidden={value !== index} style={{ height: '100%' }}>
      {children}
    </div>
  );
};

export default ChatTabWrapper;
