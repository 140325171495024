import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { useApiClient } from '../context/ApiClientContext';
import { Message, Status, ChatSession } from '../types';
import { styles } from '../styles/stylesheet';
import Chats from './Chats';
import { usePrevious } from '../hooks/UsePrevious';
import ConfirmationDialog from './ConfirmationDialog';
import { PAGE_SIZE_FOR_MESSAGES } from '../constants';

const ChatbotMessages = ({ id, status }: { id: string; status: Status }) => {
  const [chatSessionIds, setChatSessionIds] = useState<ChatSession[]>();
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);
  const [selectedChatSessionId, setSelectedChatSessionId] = useState<string>();
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [chatSessionsCount, setChatSessionsCount] = useState<number>(0);
  const [isDeleteChatSessionDialogOpen, setIsDeleteChatSessionDialogOpen] =
    useState(false);

  const isMessagesInitialized = useRef(false);

  const prevMessages = usePrevious(selectedMessages);
  const { apiClient } = useApiClient();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior = 'smooth') => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior, block: 'nearest' });
      }
    },
    [messagesEndRef],
  );

  const fetchMessagesForSelectedChatSessionId = useCallback(
    async (
      chatSessionId: string,
      pageNumber: number = 1,
    ): Promise<Message[]> => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/by-chat-session-id/${chatSessionId}?siteId=${id}&pageSize=${PAGE_SIZE_FOR_MESSAGES}&pageNumber=${pageNumber}`,
        );

        const newMessages = response.data.messages.reverse();

        return newMessages;
      } catch (error) {
        console.error('Error fetching messages for chat session:', error);
        throw error;
      }
    },
    [apiClient, id],
  );

  const deleteMessagesForSelectedChatSessionId = useCallback(
    async (chatSessionId: string) => {
      try {
        await apiClient.delete(
          `${process.env.REACT_APP_BACKEND_URL}/messages/by-chat-session-id/${chatSessionId}`,
        );
      } catch (error) {
        throw error;
      }
    },
    [apiClient],
  );

  useEffect(() => {
    if (
      prevMessages.length !== selectedMessages.length &&
      !isMessagesInitialized.current
    ) {
      scrollToBottom('auto');
      isMessagesInitialized.current = true;
    }
  }, [selectedMessages, prevMessages, scrollToBottom]);

  const handlePageChange = async (event: any, newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const fetchchatSessionsCountForSite = useCallback(async () => {
    try {
      const chatSessionCountResponse = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/chat-sessions/count`,
      );

      setChatSessionsCount(chatSessionCountResponse.data.chatSessionsCount);
    } catch (error) {
      console.error('Error fetching chat session IDs count:', error);
    }
  }, [id, apiClient]);

  const fetchChatSessionIdsForSite = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/chat-sessions?pageNumber=${currentPage}&pageSize=${7}`,
      );

      setChatSessionIds(response.data.chatSessionData);
    } catch (error) {
      console.error('Error fetching chat session IDs:', error);
    }
  }, [id, currentPage, apiClient]);

  useEffect(() => {
    fetchChatSessionIdsForSite();
  }, [fetchChatSessionIdsForSite]);

  useEffect(() => {
    fetchchatSessionsCountForSite();
  }, [fetchchatSessionsCountForSite]);

  // TODO: Move to child component
  const loadMessages = useCallback(
    async (pageNumber: number): Promise<boolean> => {
      if (!hasMoreMessages || !selectedChatSessionId) {
        return false;
      }

      try {
        const newMessages = await fetchMessagesForSelectedChatSessionId(
          selectedChatSessionId,
          pageNumber,
        );

        if (newMessages.length > 0) {
          setSelectedMessages((prevMessages) => [
            ...newMessages,
            ...prevMessages,
          ]);
        } else {
          setHasMoreMessages(false); // No more messages available
        }
      } catch (error) {
        console.error('Failed to load messages:', error);
        throw error;
      }

      return selectedMessages.length > 0;
    },
    [
      fetchMessagesForSelectedChatSessionId,
      hasMoreMessages,
      selectedMessages,
      selectedChatSessionId,
    ],
  );

  const handleSelectChatSession = useCallback(
    async (chatSessionId: string) => {
      setSelectedChatSessionId(chatSessionId);
      const messages =
        await fetchMessagesForSelectedChatSessionId(chatSessionId);
      setSelectedMessages(messages);
    },
    [setSelectedMessages, fetchMessagesForSelectedChatSessionId],
  );

  const handleDeleteChatSession = useCallback(async () => {
    if (!selectedChatSessionId) {
      return;
    }

    await deleteMessagesForSelectedChatSessionId(selectedChatSessionId);
    setIsDeleteChatSessionDialogOpen(false);
    fetchChatSessionIdsForSite();
  }, [
    deleteMessagesForSelectedChatSessionId,
    selectedChatSessionId,
    fetchChatSessionIdsForSite,
  ]);

  useEffect(() => {
    // Reset state after messages are set in loadMessages method
    setHasMoreMessages(true);
  }, [selectedMessages]);

  return (
    <>
      {selectedMessages.length > 0 ? (
        <Box sx={styles.chatContainer} display={'flex'}>
          <Box sx={styles.header}>
            <Button
              size='small'
              variant='contained'
              onClick={() => {
                setSelectedMessages([]);
              }}
            >
              BACK
            </Button>
          </Box>
          <Chats
            isOpen={true}
            loading={false}
            messages={selectedMessages}
            disableFeedback={true}
            messagesEndRef={messagesEndRef}
            onNextPage={loadMessages}
            status={status}
            chatSessionId={selectedChatSessionId!}
            showTrace={true}
          />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            {chatSessionsCount > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align='right'>Created</TableCell>
                  <TableCell align='right'>Delete</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {chatSessionIds && chatSessionIds.length > 0 ? (
                chatSessionIds.map((chatSession: ChatSession) => (
                  <TableRow key={chatSession.id}>
                    <TableCell
                      sx={(theme) => ({
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: theme.palette.grey[200],
                          ...theme.applyStyles('dark', {
                            backgroundColor: theme.palette.grey[800],
                          }),
                        },
                      })}
                      onClick={() => {
                        handleSelectChatSession(chatSession.id);
                      }}
                    >
                      {chatSession.id.split('_')[1]}
                    </TableCell>
                    <TableCell>
                      {chatSession.isClosed ? 'Closed' : 'Open'}
                    </TableCell>
                    <TableCell align='right'>
                      {DateTime.fromISO(chatSession.createdAt).toRelative()}
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton
                        onClick={() => {
                          setSelectedChatSessionId(chatSession.id);
                          setIsDeleteChatSessionDialogOpen(true);
                        }}
                        disabled={!chatSession.isClosed}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No sessions to display</TableCell>
                </TableRow>
              )}
            </TableBody>
            {chatSessionIds && chatSessionIds.length > 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={4}
                    count={chatSessionsCount}
                    rowsPerPage={7}
                    page={currentPage - 1}
                    onPageChange={handlePageChange}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
      <ConfirmationDialog
        open={isDeleteChatSessionDialogOpen}
        dialogMessage='Are you sure you want to delete this chat session?'
        onAction={handleDeleteChatSession}
        onClose={() => {
          setIsDeleteChatSessionDialogOpen(false);
          setSelectedChatSessionId('');
        }}
      />
    </>
  );
};

export default ChatbotMessages;
