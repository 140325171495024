export interface SiteConfig {
  rateLimits: {
    user: {
      points: number;
      duration: number;
    };
    site: {
      points: number;
      duration: number;
    };
  };
}

export interface SiteData {
  id: string;
  title: string;
  url: string;
  status: Status;
  depth: number;
  isPublic: boolean;
  config: SiteConfig;
  lastIndexedAt: any;
  siteIdHash: string;
  extensionTheme: ExtensionTheme;
  pagesCount: number;
  llmProvider: string;
  publicChatId: string;
}

export interface PageData {
  id: string;
  url: string;
  status: PageStatus;
  depth: number;
  createdAt: string;
}

export interface ChatSession {
  id: string;
  createdAt: string;
  isClosed: boolean;
}

export enum Status {
  Pending = 'pending',
  Scraping = 'scraping',
  Scraped = 'scraped',
  Indexing = 'indexing',
  Ready = 'ready',
}

export enum PageStatus {
  Pending = 'pending',
  Indexing = 'indexing',
  Failed = 'failed',
  Ready = 'ready',
}

export enum Feedback {
  U = 'U',
  D = 'D',
}

export type Message = {
  messageId: string;
  text: string;
  isUser: boolean;
  feedback: Feedback | null;
  isError: boolean;
  timeStamp: string;
};

export type MessageTrace = {
  userInput: string;
  chatHistory: {
    type: 'human' | 'ai';
    message: string;
  }[];
  retriever: {
    querySystemPrompt: string;
    query: string;
    retrievedDocs: { source: string; length: number }[];
  };
  systemMessage: string;
  llmOutput: {
    message: string;
    usage: { inputTokens: number; outputTokens: number; totalTokens: number };
  };
};

export type MessagesPerChatSession = {
  chatSessionId: string;
  messages: Message[];
};

export type ExtensionTheme = 'light' | 'dark' | 'system' | undefined;

export interface Topic {
  status: Status;
  extensionTheme: ExtensionTheme;
  id: string;
  llmProvider: string;
  name: string;
  publicChatId: string;
  createdAt: string;
  sites: { url: string; status: Status }[];
}

export enum ChatSessionSource {
  Dashboard = 'dashboard',
  Public = 'public',
  Extension = 'extension',
}

export interface TopicData {
  id: string;
  name: string;
}

export type PageContentData = {
  text: string;
  metadata: string;
};
