import { useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider } from '@toolpad/core';

import { useSession } from '../context/SessionContext';

export default function DasboardLayout() {
  const { isUserAuthenticated, signIn, signOut, user } = useSession();
  const navigate = useNavigate();

  const appProviderSession = useMemo(() => {
    if (user) {
      return {
        user: {
          name: user.name,
          image: user.picture,
        },
      };
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <AppProvider
      branding={{
        logo: <img src='/pudina-chaas.png' alt='Chaas logo' />,
        title: 'Chaas',
        homeUrl: '/dashboard',
      }}
      authentication={{
        signIn,
        signOut,
      }}
      session={appProviderSession}
    >
      <DashboardLayout hideNavigation>
        {/* TODO: Width for container and header */}
        {/* <PageContainer> */}
        {isUserAuthenticated && <Outlet />}
        {/* </PageContainer> */}
      </DashboardLayout>
    </AppProvider>
  );
}
