import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import type { Root } from 'mdast';
import { visit } from 'unist-util-visit';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import remarkBreaks from 'remark-breaks';

import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNotifications } from '@toolpad/core';

import { Message, MessageTrace, Status } from '../types';
import { styles } from '../styles/stylesheet';
import Feedback from './Feedback';
import Loading from './Loading';
import { usePrevious } from '../hooks/UsePrevious';
import { useThrottle } from '../hooks/UseThrottle';
import { useApiClient } from '../context/ApiClientContext';
import MessageTraceDialog from './MessageTraceDialog';
import { useSession } from '../context/SessionContext';

const SCROLL_THRESHOLD = 20;

const Chats = ({
  isOpen,
  messages,
  loading,
  disableFeedback,
  messagesEndRef,
  onNextPage,
  status,
  chatSessionId,
  showTrace,
}: {
  isOpen: boolean;
  messages: Message[];
  loading: boolean;
  disableFeedback: boolean;
  messagesEndRef: React.MutableRefObject<HTMLDivElement | null>;
  onNextPage: (pageNumber: number) => Promise<boolean>;
  status: Status;
  chatSessionId: string;
  showTrace: boolean;
}) => {
  const prevMessages = usePrevious(messages);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [chatLoading, setChatLoading] = useState(false);
  const [isMessageTraceDialogOpen, setIsMessageTraceDialogOpen] =
    useState(false);
  const [selectedMessageTrace, setSelectedMessageTrace] =
    useState<MessageTrace>();

  const { apiClient } = useApiClient();
  const { isAdmin } = useSession();
  const notifications = useNotifications();

  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior = 'smooth') => {
      if (messagesContainerRef && messagesContainerRef.current) {
        const scrollHeight = messagesContainerRef.current.scrollHeight;
        const clientHeight = messagesContainerRef.current.clientHeight;

        // Set scrollTop to scrollHeight minus clientHeight to scroll to the bottom
        messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    },
    [messagesContainerRef],
  );

  const setTrace = useCallback((trace: MessageTrace) => {
    setSelectedMessageTrace(trace);
    setIsMessageTraceDialogOpen(true);
  }, []);

  const hasScrollbar = useCallback(() => {
    const container = messagesContainerRef.current;
    return container ? container.scrollHeight > container.clientHeight : false;
  }, []);

  useEffect(() => {
    const ensureScrollable = async () => {
      const nextPage = pageNumber + 1;
      const hasMoreMessages = await onNextPage(nextPage);

      setTimeout(() => scrollToBottom('auto'), 0);

      if (!hasMoreMessages) {
        return;
      }

      setPageNumber(nextPage);
    };

    if (messages.length === 0 || !isOpen) {
      return;
    }

    if (!hasScrollbar()) {
      ensureScrollable();
    }
  }, [onNextPage, pageNumber, isOpen, messages, hasScrollbar, scrollToBottom]);

  useEffect(() => {
    if (prevMessages.length === 0 && messages.length !== 0) {
      scrollToBottom('auto');
    }
  }, [messages, hasScrollbar, scrollToBottom, prevMessages]);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom('auto');
    }
  }, [isOpen, scrollToBottom]);

  const renderMarkdown = (text: string) => {
    return (
      <ReactMarkdown
        remarkPlugins={[
          () => (tree: Root) => {
            visit(tree, 'code', (node) => {
              node.lang = node.lang ?? 'plaintext';
            });
          },
          remarkGfm,
          remarkBreaks,
        ]}
        components={{
          p: ({ children }) => {
            return <Typography gutterBottom>{children}</Typography>;
          },
          li: ({ children }) => {
            return <Typography component='li'>{children}</Typography>;
          },
          code: ({ children, className, node, ref, ...rest }) => {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag='div'
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
                style={vs2015}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
          a: ({ href, children }) => {
            return (
              <Link
                color='#1976d2'
                underline='hover'
                target='_blank'
                rel='noopener noreferrer'
                href={href}
                sx={styles.wrappedText}
              >
                {children}
              </Link>
            );
          },
        }}
      >
        {text}
      </ReactMarkdown>
    );
  };

  const fetchTrace = useCallback(
    async (messageId: string) => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/${messageId}/trace`,
        );

        if (!response.data.trace) {
          notifications.show('Error fetching trace', {
            severity: 'error',
            autoHideDuration: 3000,
          });

          return;
        }

        setTrace(response.data.trace);
      } catch (error) {
        console.error('Error fetching trace:', error);
        throw error;
      }
    },
    [apiClient, setTrace, notifications],
  );

  // TODO: Fetch messages until chat container becomes scrollable / all messages are fetched
  const handleScroll = useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      if (chatLoading || !messagesContainerRef.current) {
        return;
      }

      setChatLoading(true);

      if (e.currentTarget.scrollTop <= SCROLL_THRESHOLD) {
        const nextPage = pageNumber + 1;
        const hasMoreMessages = await onNextPage(nextPage);

        if (!hasMoreMessages) {
          setChatLoading(false);
          return;
        }

        const oldScrollHeight = messagesContainerRef.current.scrollHeight;
        const oldScrollTop = messagesContainerRef.current.scrollTop;
        setPageNumber(nextPage);

        setTimeout(() => {
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop =
              messagesContainerRef.current.scrollHeight -
              oldScrollHeight +
              oldScrollTop;
          }
        }, 0);
      }
      setChatLoading(false);
    },
    [onNextPage, messagesContainerRef, chatLoading, pageNumber],
  );

  const throttledHandleScroll = useThrottle(handleScroll, 10);

  return (
    <>
      <Box
        ref={messagesContainerRef}
        onScroll={throttledHandleScroll}
        sx={[
          (theme) => ({
            backgroundColor: theme.palette.background.default,
            ...styles.messagesArea,
          }),
        ]}
      >
        {![Status.Ready, Status.Pending].includes(status) ? (
          <Box sx={styles.displayChatStatus}>
            <Typography>
              The site is currently being{' '}
              {[Status.Indexing, Status.Scraped].includes(status)
                ? 'indexed'
                : 'scraped'}
              , please check back later...
            </Typography>
          </Box>
        ) : status === Status.Pending ? (
          <Box sx={styles.displayChatStatus}>
            <CircularProgress />
            <Typography variant='h6' sx={styles.loadingText}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <List sx={{ padding: 0 }}>
            {chatLoading ? (
              <Box sx={styles.chatLoader}>
                <CircularProgress />
              </Box>
            ) : (
              <></>
            )}
            {messages.map((message, index) => (
              <ListItem
                key={`${message.messageId}-${message.isUser ? 'user' : 'bot'}`}
                sx={{
                  display: 'flex',
                  justifyContent: message.isUser ? 'flex-end' : 'flex-start',
                  mb: 1,
                }}
              >
                <Card
                  sx={[
                    (theme) => ({
                      backgroundColor: message.isUser
                        ? theme.palette.background.userMessage
                        : theme.palette.background.paper,
                      maxWidth:
                        // isFullScreen ? '60%' :
                        '70%',
                      ...styles.messageCard,
                    }),
                  ]}
                >
                  <div>
                    {renderMarkdown(message.text)}
                    {!message.isUser && !message.isError && (
                      <Box sx={styles.feedback}>
                        <Feedback
                          message={message}
                          disabled={disableFeedback}
                          chatSessionId={chatSessionId}
                        />
                        {showTrace && isAdmin && (
                          <div>
                            <Tooltip title='Show trace' arrow>
                              <IconButton
                                onClick={() => {
                                  fetchTrace(message.messageId);
                                }}
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    )}
                  </div>
                </Card>
              </ListItem>
            ))}
            {loading && (
              <ListItem>
                <Card
                  sx={[
                    (theme) => ({
                      backgroundColor: theme.palette.background.paper,
                      ...styles.ellipsisContainer,
                    }),
                  ]}
                >
                  <Loading />
                </Card>
              </ListItem>
            )}
            <div ref={messagesEndRef} />
          </List>
        )}
      </Box>
      {selectedMessageTrace && (
        <MessageTraceDialog
          open={isMessageTraceDialogOpen}
          onClose={() => setIsMessageTraceDialogOpen(false)}
          trace={selectedMessageTrace}
        />
      )}
    </>
  );
};

export default Chats;
