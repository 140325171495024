import { createTheme } from '@mui/material/styles';

// Reference: https://mui.com/material-ui/customization/palette/#typescript
declare module '@mui/material/styles' {
  interface TypeBackground {
    userMessage?: string;
  }
}

const themeOptions = {
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: '#f5f5f5',
          userMessage: '#c1e1c1',
          paper: '#ededed',
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: '#181818',
          userMessage: '#075e54',
        },
      },
    },
  },
};

export const chatsTheme = createTheme(themeOptions);

export const pageContentTheme = createTheme(themeOptions);
