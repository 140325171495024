import React, { useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotifications } from '@toolpad/core';

import { LLM_PROVIDERS } from '../constants';
import { styles } from '../styles/stylesheet';

type Props = {
  open: boolean;
  onClose: () => void;
  onAction: (
    name: string,
    llmProvider: string,
    llmKey: string,
  ) => Promise<void>;
};

type FormValues = {
  topicName: string;
  llmProvider: string;
  apiKey: string;
};

export default function CreateTopicDialog({
  open,
  onClose: handleClose,
  onAction: handleAction,
}: Props) {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      topicName: '',
      llmProvider: LLM_PROVIDERS[0].value,
      apiKey: '',
    },
  });

  const notifications = useNotifications();

  const llmProvider = watch('llmProvider');

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const { topicName, llmProvider, apiKey } = data;

      if (!topicName.trim()) {
        setError('topicName', {
          type: 'required',
          message: 'Field is required.',
        });

        return;
      }

      if (!apiKey.trim()) {
        setError('apiKey', {
          type: 'required',
          message: 'Field is required.',
        });

        return;
      }

      try {
        await handleAction(topicName, llmProvider, apiKey);
        handleClose();
      } catch (error) {
        notifications.show('An error occurred while creating the topic', {
          severity: 'error',
          autoHideDuration: 3000,
        });
      } finally {
        reset();
      }
    },
    [handleAction, handleClose, notifications, reset, setError],
  );

  const apiKeyLink = useMemo(() => {
    return LLM_PROVIDERS.find((provider) => provider.value === llmProvider)
      ?.apiKeyLink;
  }, [llmProvider]);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        Enter the following details to create a new topic
      </DialogTitle>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={styles.urlForm}>
            <Controller
              name='topicName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Topic'
                  variant='outlined'
                  fullWidth
                  autoComplete='off'
                  error={!!errors.topicName}
                  helperText={errors.topicName?.message}
                />
              )}
            />
            <Controller
              name='llmProvider'
              control={control}
              render={({ field }) => (
                <TextField {...field} select variant='outlined' label='Model'>
                  {LLM_PROVIDERS.map((provider) => (
                    <MenuItem key={provider.value} value={provider.value}>
                      {provider.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Typography
              variant='body2'
              color='textSecondary'
              style={{ marginTop: '10px' }}
            >
              Visit{' '}
              <a href={apiKeyLink} target='_blank' rel='noopener noreferrer'>
                {apiKeyLink}
              </a>{' '}
              to get the API key.
            </Typography>
            <Controller
              name='apiKey'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='API Key'
                  variant='outlined'
                  fullWidth
                  type='password'
                  autoComplete='off'
                  error={!!errors.apiKey}
                  helperText={errors.apiKey?.message}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <LoadingButton
            type='submit'
            color='primary'
            variant='contained'
            size='small'
            loading={isSubmitting}
          >
            Add
          </LoadingButton>
          <Button
            onClick={() => {
              handleClose();
              reset();
            }}
            size='small'
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
