import { AxiosInstance } from 'axios';
import { useState, useEffect, useCallback } from 'react';

import { Status } from '../types';
import { SITE_POLL_INTERVAL } from '../constants';

interface UseIndexingProgressProps {
  indexedPagesCount: number;
  totalPagesCount: number;
  fetchIndexingProgress: () => Promise<void>;
  fetchTotalPagesCount: () => Promise<void>;
  normalizeIndexedPagesCount: (value: number) => number;
}

export const useIndexingProgress = (
  siteId: string | undefined,
  apiClient: AxiosInstance,
  status: Status,
): UseIndexingProgressProps => {
  const [indexedPagesCount, setIndexedPagesCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);

  const fetchIndexingProgress = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${siteId}/pages/count?status=ready`,
      );

      const countResponse = response.data.pagesCount;
      setIndexedPagesCount(countResponse);
    } catch (error) {
      console.error('Error fetching site status:', error);
    }
  }, [siteId, apiClient]);

  useEffect(() => {
    if (status !== Status.Indexing || !totalPagesCount) {
      return;
    }

    fetchIndexingProgress();

    const timerId = setInterval(() => {
      fetchIndexingProgress();
    }, SITE_POLL_INTERVAL);

    return () => clearInterval(timerId);
  }, [fetchIndexingProgress, status, totalPagesCount]);

  const fetchTotalPagesCount = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${siteId}/pages/count`,
      );

      const totalPagesResponse = response.data.pagesCount;
      setTotalPagesCount(totalPagesResponse);
    } catch (error) {
      console.error('Error fetching site status:', error);
    }
  }, [siteId, apiClient]);

  useEffect(() => {
    if (status !== Status.Indexing) {
      return;
    }

    fetchTotalPagesCount();
  }, [fetchTotalPagesCount, status]);

  const normalizeIndexedPagesCount = useCallback(
    (value: number) => {
      if (!totalPagesCount) {
        return 0;
      }

      return (value * 100) / totalPagesCount;
    },
    [totalPagesCount],
  );

  return {
    indexedPagesCount,
    totalPagesCount,
    fetchIndexingProgress,
    fetchTotalPagesCount,
    normalizeIndexedPagesCount,
  };
};
