import React, { useState } from 'react';

import { Box, Chip, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styles } from '../styles/stylesheet';
import ExpandMore from './ExpandMore';

const TraceDataWrapper = ({
  title,
  tokens,
  children,
}: React.PropsWithChildren<{ title: string; tokens?: number }>) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box sx={styles.messageTraceTokensContainer}>
        <Typography fontWeight={'bold'}>{title}</Typography>
        <Box sx={styles.messageTraceTokensContainer}>
          {tokens !== undefined && (
            <Typography sx={{ p: 1, m: 1 }}>
              Tokens: <Chip label={tokens} variant='outlined' />
            </Typography>
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Box>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Box sx={styles.messageTraceBackground}>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {children}
            </pre>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default TraceDataWrapper;
