import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { styles } from '../styles/stylesheet';
import { SiteConfig } from '../types';

type Props = {
  values: SiteConfig;
  open: boolean;
  onClose: () => void;
  onAction: (value: SiteConfig) => void;
};

type FormValues = {
  userPoints: number;
  sitePoints: number;
};

export default function ConfigureDialog({
  values,
  open,
  onClose: handleClose,
  onAction: handleAction,
}: Props) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      userPoints: values.rateLimits.user.points || 0,
      sitePoints: values.rateLimits.site.points || 0,
    },
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const updatedConfig: SiteConfig = {
        ...values,
        rateLimits: {
          user: { ...values.rateLimits.user, points: data.userPoints },
          site: { ...values.rateLimits.site, points: data.sitePoints },
        },
      };

      handleAction(updatedConfig);
      handleClose();
      reset();
    },
    [handleAction, handleClose, values, reset],
  );

  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogTitle>Configure rate limits</DialogTitle>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography>User:</Typography>
          <Box sx={styles.configureDialogBox}>
            <Controller
              name='userPoints'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: 70 }}
                  type='number'
                  size='small'
                  variant='outlined'
                  fullWidth
                  autoComplete='off'
                />
              )}
            />
            <Typography sx={{ marginTop: 1 }}>requests / minute</Typography>
          </Box>
          <Typography sx={{ marginTop: 1 }}>Site:</Typography>
          <Box sx={styles.configureDialogBox}>
            <Controller
              name='sitePoints'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: 70 }}
                  type='number'
                  size='small'
                  variant='outlined'
                  fullWidth
                  autoComplete='off'
                />
              )}
            />
            <Typography sx={{ marginTop: 1 }}>requests / minute</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <LoadingButton
            type='submit'
            color='primary'
            variant='contained'
            size='small'
            loading={isSubmitting}
          >
            Update
          </LoadingButton>
          <Button
            onClick={() => {
              handleClose();
              reset();
            }}
            size='small'
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
