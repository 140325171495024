import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth0, User } from '@auth0/auth0-react';

import { useApiClient } from './ApiClientContext';

export interface SessionContextValue {
  isUserAuthenticated: boolean;
  isAdmin: boolean;
  signIn: () => void;
  signOut: () => void;
  user: User | undefined;
}

export const SessionContext = createContext<SessionContextValue>({
  isUserAuthenticated: false,
  isAdmin: false,
  signIn: () => {},
  signOut: () => {},
  user: undefined,
});

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [accessToken, setAccessToken] = useState<string | null>();
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();
  const { apiClient } = useApiClient();

  useEffect(() => {
    const getAuthToken = async () => {
      const token = await getAccessTokenSilently();

      setAccessToken(token);
    };

    if (isAuthenticated) {
      getAuthToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleLogout = async () => {
    try {
      await apiClient.post(`${process.env.REACT_APP_BACKEND_URL}/users/logout`);
      logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    setIsUserAuthenticated(false);

    if (!accessToken || !user) {
      return;
    }

    const setAuthAndUser = async () => {
      try {
        await apiClient.post(
          `${process.env.REACT_APP_BACKEND_URL}/users/set-auth-token`,
          {
            token: accessToken,
          },
        );

        const response = await apiClient.post(
          `${process.env.REACT_APP_BACKEND_URL}/users`,
          {
            isVerified: user.email_verified,
          },
        );

        setIsAdmin(response.data.isAdmin);
        setIsUserAuthenticated(true);
      } catch (error) {
        console.error('Authentication failed:', error);
      }
    };

    setAuthAndUser();
  }, [accessToken, apiClient, user]);

  return (
    <SessionContext.Provider
      value={{
        isUserAuthenticated,
        isAdmin,
        signIn: () => loginWithRedirect(),
        signOut: () => handleLogout(),
        user,
      }}
    >
      {!isLoading && children}
    </SessionContext.Provider>
  );
};

// Custom hook to use the SessionContext
export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
