export const DESCRIPTION_TEXT = `Ever wished for a chatbot that fits in anywhere? Just like a glass of
  chaas pairs perfectly with any meal, our chatbot blends seamlessly
  into any website. Smooth, refreshing, and always ready to serve up
  answers. Add some Chaas to your life!`;

export const MARKETING_TEXT = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
  voluptate velit esse cillum dolore eu fugiat nulla pariatur.`;

export const INDEXED_SITES = [
  {
    name: 'Solidity Docs',
    url: 'https://docs.soliditylang.org/en/v0.8.28',
  },
  {
    name: 'Ethers Docs',
    url: 'https://docs.ethers.org/v6',
  },
  {
    name: 'Luigi Docs',
    url: 'https://luigi.readthedocs.io/en/stable',
  },
  {
    name: 'Ethereum EIPs',
    url: 'https://eips.ethereum.org',
  },
  {
    name: "Vitalik's Blog",
    url: 'https://vitalik.eth.limo',
  },
];

export const CONTACT_EMAIL_ADDRESS = 'contact@deepstacksoft.com';

export const CHAAS_DIV_ID = 'chaas';

export const SITE_POLL_INTERVAL = 5000; // ms

export const LLM_PROVIDERS = [
  {
    label: 'Google',
    value: 'google',
    apiKeyLink: 'https://ai.google.dev/gemini-api/docs/api-key',
  },
  {
    label: 'Mistral',
    value: 'mistral',
    apiKeyLink: 'https://console.mistral.ai/api-keys/',
  },
  {
    label: 'Groq',
    value: 'groq',
    apiKeyLink: 'https://console.groq.com/keys',
  },
  {
    label: 'Cerebras',
    value: 'cerebras',
    apiKeyLink: 'https://cloud.cerebras.ai/',
  },
  {
    label: 'Anthropic',
    value: 'anthropic',
    apiKeyLink: 'https://console.anthropic.com/settings/keys',
  },
  {
    label: 'OpenAI',
    value: 'openai',
    apiKeyLink: 'https://platform.openai.com/api-keys',
  },
];

export const PAGE_SIZE_FOR_MESSAGES = 3;
